<template>
	<div>
		<b-modal
			id="modal-edit-payment-type"
			cancel-variant="outline-secondary"
			:ok-title="$t('Submit')"
			:cancel-title="$t('Close')"
			ok-variant="primary"
			modal-class="modal-primary"
			centered
			size="md"
			:title="$t('Update payment type')"
			@ok="confirmEditPaymentType"
			@hidden="resetModal()"
		>
			<b-overlay
				:show="show"
				variant="transparent"
				no-wrap
			/>
			<!-- form -->
			<validation-observer ref="editPaymentType">
				<b-form
					ref=""
					method="POST"
					class="auth-reset-password-form mt-2"
					@submit.prevent="validationFormPaymentType"
				>
					<!-- Field: Name -->
					<b-form-group
						label-for="name"
					>
						<label class="mb-1"><span class="text-danger">*</span> {{ $t('Type name') }}</label>
						<validation-provider
							#default="{ errors }"
							name="name"
							rules="required"
						>
							<b-form-input
								id="name"
								v-model="paymentTypeData.name"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								disabled
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<!-- Code -->
					<b-form-group
						label-for="code"
					>
						<label class="mb-1"><span class="text-danger">*</span> {{ $t('Type code') }}</label>
						<validation-provider
							#default="{ errors }"
							name="code"
							rules="required"
						>
							<b-form-input
								id="code"
								v-model="paymentTypeData.code"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<!-- Transaction Type -->
					<validation-provider
						#default="{ errors }"
						name="transaction_type"
						rules="required"
					>
						<b-form-group
							label-for="type"
						>
							<label class="mb-1"><span class="text-danger">*</span> {{ $t('Transaction type') }}</label>
							<v-select
								v-model="paymentTypeData.transaction_type"
								:options="transactionTypeOptions"
								:reduce="val => val.value"
								input-id="type"
								disabled
							/>

							<small class="text-danger">{{ errors[0] }}</small>
						</b-form-group>
					</validation-provider>

					<!-- Currencies -->
					<!-- <b-form-group
						label-for="type"
					>
						<label class="mb-1">{{ $t('Currency') }}</label>
						<b-form-input
							id="currency"
							v-model="paymentTypeData.currency"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							disabled
						/>
					</b-form-group> -->

					<!-- Payment Type Affiliate Fee -->
					<b-form-group
						label-for="affiliate_fee"
					>
						<label class="mb-1"><span class="text-danger">*</span> Payment Type Affiliate Fee</label>
						<b-form-spinbutton
							id="affiliate_fee"
							v-model="paymentTypeData.merchant_fee"
							min="0"
							max="100"
							step="0.1"
						/>
					</b-form-group>

					<!-- Status -->
					<b-form-group
						label-for="status"
					>
						<label class="mb-1 d-block">{{ $t('Status') }}</label>
						<b-form-radio
							v-model="paymentTypeData.status"
							name="status"
							value="1"
							inline
						>
							{{ $t('Active') }}
						</b-form-radio>
						<b-form-radio
							v-model="paymentTypeData.status"
							name="status"
							value="2"
							inline
						>
							{{ $t('Inactive') }}
						</b-form-radio>
					</b-form-group>

					<!-- Min Amount -->
					<b-form-group
						label-for="min_amount"
					>
						<label class="mb-1"><span class="text-danger">*</span> {{ $t('Min amount') }}</label>
						<validation-provider
							#default="{ errors }"
							name="min_amount"
							rules="required|numeric|min_value:1|max_value:300000"
						>
							<b-form-input
								id="min_amount"
								v-model="paymentTypeData.min_amount"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
							<small class="text-danger d-block" v-if="error_min_max">{{ error_min_max }}</small>
						</validation-provider>
					</b-form-group>

					<!-- Max Amount -->
					<b-form-group
						label-for="max_amount"
					>
						<label class="mb-1"><span class="text-danger">*</span> {{ $t('Max amount') }}</label>
						<validation-provider
							#default="{ errors }"
							name="max_amount"
							rules="required|numeric"
						>
							<b-form-input
								id="max_amount"
								v-model="paymentTypeData.max_amount"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

				</b-form>
			</validation-observer>

		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BOverlay, BFormSpinbutton, BFormRadio, BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import paymentTypeStoreModule from './paymentTypeStoreModule'

export default {
	components: {
		BOverlay, BFormSpinbutton, BFormRadio, BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		transactionTypeOptions: {
			type: Array,
			default: null,
		},
		paymentTypeDetail: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			paymentTypeData: {
				paymentTypeId: '',
				name: '',
				code: '',
				transaction_type: '',
				merchant_fee: 0,
				owner_fee: 0,
				status: 1,
				min_amount: null,
				max_amount: null,
				currency: 'VND',
			},
			error_min_max: null,
			show: false,
		}
	},
	watch: {
		async paymentTypeDetail(newVal) {
			if (newVal) {
				this.show = true
				await this.fillPaymentTypeData(newVal)
				this.show = false
			}
		},
		'paymentTypeData.min_amount': {
			handler(newVal) {
				if (this.paymentTypeData.max_amount) {
					if (parseFloat(newVal) > parseFloat(this.paymentTypeData.max_amount)) {
						this.error_min_max = 'Min amount must be less than max amount'
					} else {
						this.error_min_max = null
					}
				}
			},
			deep: true
		},
		'paymentTypeData.max_amount': {
			handler(newVal) {
				if (this.paymentTypeData.min_amount) {
					if (parseFloat(this.paymentTypeData.min_amount) > parseFloat(newVal)) {
						this.error_min_max = 'Min amount must be less than max amount'
					} else {
						this.error_min_max = null
					}
				}
			},
			deep: true
		}
	},
	mounted() {
	},
	created() {
	},
	destroyed() {
	},
	methods: {
		validationFormPaymentType() {
			this.$refs.editPaymentType.validate().then(success => {
				if (success && !this.error_min_max) {
					store.dispatch('payment-type/editPaymentType', this.paymentTypeData)
						.then(response => {
							if (response.status === 200) {
								if (response.data.code === '00') {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message || 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$nextTick(() => {
										setTimeout(() => {
											this.$bvModal.hide('modal-edit-payment-type')
											this.resetModal()
										}, '500')
									})
									this.$emit('refetch-data')
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								}
							}
						})
						.catch(error => {
							const { response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		fillPaymentTypeData(data) {
			this.paymentTypeData.paymentTypeId = data.paymentTypeId
			this.paymentTypeData.name = data.name
			this.paymentTypeData.code = data.code
			this.paymentTypeData.currency = data.currency
			this.paymentTypeData.transaction_type = data.transaction_type
			this.paymentTypeData.merchant_fee = parseFloat(data.merchant_fee)
			this.paymentTypeData.owner_fee = parseFloat(data.owner_fee)
			this.paymentTypeData.status = data.status
			this.paymentTypeData.min_amount = parseFloat(data.min_amount) / 1000
			this.paymentTypeData.max_amount = parseFloat(data.max_amount) / 1000
		},
		resetModal() {
			this.$emit('update:paymentTypeDetail', null)
			this.paymentTypeData.paymentTypeId = ''
			this.paymentTypeData.name = ''
			this.paymentTypeData.code = ''
			this.paymentTypeData.currency = ''
			this.paymentTypeData.transaction_type = ''
			this.paymentTypeData.merchant_fee = 0
			this.paymentTypeData.owner_fee = 0
			this.paymentTypeData.status = 1
			this.paymentTypeData.min_amount = ''
			this.paymentTypeData.max_amount = ''
		},
		confirmEditPaymentType(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormPaymentType()
		}
	},
	setup() {
		if (!store.hasModule('payment-type')) store.registerModule('payment-type', paymentTypeStoreModule)
		return {
		}
	},
}
</script>